import { gql } from '@apollo/client';
import { COMMON_LIST_ARGS, toGqlArgs, toGqlVariables } from '../utils';

const LIST_ARGS = {
  ...COMMON_LIST_ARGS,
  status: 'PaymentStatus=null',
  userId: 'ID',
  discountCode: 'String',
  orderId: 'String',
  paymentId: 'String',
  referred: 'Boolean',
  mode: 'PaymentMode',
  requestSource: 'SignupSourceType',
};

const LIST = gql`
  query ListPayments${toGqlVariables(LIST_ARGS)} {
    items: payments${toGqlArgs(LIST_ARGS)} {
      nodes {
        id
        user {
          id
          name
          isPlusMember
        }
        amount
        orderId
        paymentId
        paymentLink
        status
        type
        mode
        discountCode
        requestSource
        error
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;

const CREATE_PAYMENT_LINK = gql`
  mutation CreatePaymentLink($input: PaymentLinkInput!) {
    createPaymentLink(input: $input) {
      id
    }
  }
`;

export { LIST, CREATE_PAYMENT_LINK };

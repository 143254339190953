import { gql } from '@apollo/client';
import {
  COMMON_LIST_ARGS,
  COMMON_SEARCH_ARGS,
  IMAGE_FIELDS,
  LANGUAGE_FIELDS,
  toGqlArgs,
  toGqlVariables,
} from 'api/utils';

const commonFields = `
id
title { ${LANGUAGE_FIELDS} }
text{ ${LANGUAGE_FIELDS} }
status
imageId
image {
  ${IMAGE_FIELDS}
}
examCategoryId
itemType
itemId
target
publishDate
published
`;

const NOTIFICATION_ARGS = {
  target: 'NotificationTargets',
  phrase: 'String',
  itemType: 'PushNotificationItemType',
  subType: 'String',
  publishDate: '[DateTime!]',
  published: 'Boolean',
};

const LIST_ARGS = { ...COMMON_LIST_ARGS, ...NOTIFICATION_ARGS };
const SEARCH_ARGS = { ...COMMON_SEARCH_ARGS, ...NOTIFICATION_ARGS };

const itemFields = `
...on PostPushNotification {
  item {
    id
    title {
      en
    }
  }
  subType
}
...on VideoPushNotification {
  item {
    id
    title {
      en
    }
  }
  subType
}
...on TestPushNotification {
  item {
    id
    name {
      en
    }
  }
  subType
}
...on TestSeriesPushNotification {
  item {
    id
    name {
      en
    }
  }
}
...on LiveClassPushNotification {
  item {
    id
    name {
      en
    }
  }
}
`;

const GET = gql`
  query GetPushNotification($id: ID!) {
    pushNotification(id: $id) {
      ${commonFields}
    }
  }
`;

const TEST = gql`
  mutation TestPushNotification($data: TestPushNotificationInput!) {
    testPushNotification(input: $data)
  }
`;

const CREATE = gql`
  mutation CreatePushNotification($data: CreatePushNotificationInput!) {
    createPushNotification(input: $data) {
      ${commonFields}
    }
  }
`;

const UPDATE = gql`
  mutation UpdatePushNotification($data: UpdatePushNotificationInput!) {
    updatePushNotification(input: $data) {
      ${commonFields}
    }
  }
`;

const LIST = gql`
  query ListPushNotifications${toGqlVariables(LIST_ARGS)} {
    items: pushNotifications${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${commonFields}
        ${itemFields}
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;
const SEARCH = gql`
  query SearchPushNotifications${toGqlVariables(SEARCH_ARGS)} {
    items: pushNotifications${toGqlArgs(SEARCH_ARGS)} {
      nodes {
        value: id
        label: title {
          ${LANGUAGE_FIELDS}
        }
      }
    }
  }
`;

export { GET, TEST, CREATE, UPDATE, LIST, SEARCH };

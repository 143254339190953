import { gql } from '@apollo/client';
import { omit } from 'lodash';
import { COMMON_LIST_ARGS, toGqlVariables, toGqlArgs } from '../utils';

const LIST_ARGS = {
  ...omit(COMMON_LIST_ARGS, ['phrase']),
  testSeriesId: 'ID',
  userId: 'ID',
  status: 'TestAttemptStatus',
};

const LIST_TEST_ATTEMPTS = gql`
  query ListTestAttemptss${toGqlVariables({ ...LIST_ARGS, testId: 'ID!' })} {
    items: testAttempts${toGqlArgs({ ...LIST_ARGS, testId: 'ID!' })} {
      nodes {
        id
        status
        createdAt
        result {
          scores {
            total
            correct
            attempt
            timeTaken
            rank
          }
        }
        user {
          id
          name
          email
          mobile
          status
          location {
            city
          }
          emailVerified
          mobileVerified
          signupSource
          createdAt
          signupReferer
          isPlusMember
        }
      }
      totalCount
    }
    test(id: $testId) {
      name {
        en
      }
    }
  }
`;

const LIST_USER_ATTEMPTS = gql`
  query ListUserTestAttemptss${toGqlVariables({ ...LIST_ARGS, testId: 'ID' })} {
    items: testAttempts${toGqlArgs({ ...LIST_ARGS, testId: 'ID!' })} {
      nodes {
        id
        status
        createdAt
        testData {
          id
          name { en }
          type
          paid
          exams {
            id
            shortName { en }
          }
          publishDate
        }
        result {
          scores {
            total
            correct
            attempt
            timeTaken
          }
        }
      }
      totalCount
    }
  }
`;

export { LIST_TEST_ATTEMPTS, LIST_USER_ATTEMPTS };

import { gql } from '@apollo/client';
import { COMMON_LIST_ARGS, toGqlArgs, toGqlVariables } from '../utils';

const LIST_ARGS = {
  ...COMMON_LIST_ARGS,
  status: 'PayoutStatus=null',
  userId: 'ID',
  type: 'PayoutType',
};

const GET = gql`
  query GetPayout($id: ID!) {
    payout(id: $id) {
      statusHistory {
        status
        message
        time
      }
    }
  }
`;

const LIST = gql`
  query ListPayouts${toGqlVariables(LIST_ARGS)} {
    items: payouts${toGqlArgs(LIST_ARGS)} {
      nodes {
        id
        user {
          id
          name
          isPlusMember
        }
        amount
        beneficiaryId
        status
        type
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;

const CREATE = gql`
  mutation CreatePayout($beneficiaryId: ID!) {
    createPayout(beneficiaryId: $beneficiaryId) {
      id
    }
  }
`;

const GET_REDEEM_DATA = gql`
  query Me {
    me {
      redeemableAmount
    }
    beneficiaries {
      id
      type
      name
    }
  }
`;

const CREATE_BENEFICIARY = gql`
  mutation CreateBeneficiary($input: CreateBeneficiaryInput!) {
    createBeneficiary(input: $input) {
      id
      type
      name
    }
  }
`;

export { GET, LIST, CREATE, GET_REDEEM_DATA, CREATE_BENEFICIARY };

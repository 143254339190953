import { gql } from '@apollo/client';
import {
  COMMON_LIST_ARGS,
  COMMON_SEARCH_ARGS,
  IMAGE_FIELDS,
  LANGUAGE_FIELDS,
  toGqlVariables,
  toGqlArgs,
  ALL_LANGUAGE_FIELDS,
} from '../utils';

const POST_ARGS = {
  publishedOnly: 'Boolean = false',
  publishDate: '[DateTime!]',
  authorId: 'ID',
  type: 'PostType',
  examIds: '[ID!]',
  topicId: 'ID',
  bookId: 'ID',
  subjectId: 'ID',
  chapterId: 'ID',
  subtopicId: 'ID',
};
const LIST_ARGS = { ...COMMON_LIST_ARGS, ...POST_ARGS };
const SEARCH_ARGS = { ...COMMON_SEARCH_ARGS, ...POST_ARGS };

const commonFields = `
  id
  title{ ${ALL_LANGUAGE_FIELDS} }
  type
  status
  publishDate
  availableLanguages
`;

const GET = gql`
  query GetPost($id: ID!) {
    post(id: $id) {
      ${commonFields}
      topicId
      authorId
      bookId
      chapterId
      subtopicId
      examIds
      imageId
      subjectId
      image { ${IMAGE_FIELDS} }
      content { ${ALL_LANGUAGE_FIELDS} }
      sections{
        title{ ${ALL_LANGUAGE_FIELDS} }
        content{ ${ALL_LANGUAGE_FIELDS} }
      }
      featured
      featuredTill
      topperName
    }
  }
`;

const LIST = gql`
  query ListPosts${toGqlVariables(LIST_ARGS)} {
    items: posts${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${commonFields}
        topic {
          id
          name { ${LANGUAGE_FIELDS} }
        }
        book {
          id
          name
        }
        subject {
          id
          name { ${LANGUAGE_FIELDS} }
        }
        author {
          id
          name
        }
        exams {
          id
          shortName{ ${LANGUAGE_FIELDS} }
        }
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;

const CREATE = gql`
  mutation CreatePost($input: CreatePostInput!) {
    createPost(input: $input) {
      id
      title { ${ALL_LANGUAGE_FIELDS} }
    }
  }
`;

const UPDATE = gql`
  mutation UpdatePost($input: UpdatePostInput!) {
    updatePost(input: $input) {
      id
      title { ${ALL_LANGUAGE_FIELDS} }
    }
  }
`;

const SEARCH = gql`
  query SearchPosts${toGqlVariables(SEARCH_ARGS)} {
    items: posts${toGqlArgs(SEARCH_ARGS)} {
      nodes{
        value: id
        label: title { ${LANGUAGE_FIELDS} }
      }
    }
  }
`;

export { GET, LIST, CREATE, UPDATE, SEARCH };

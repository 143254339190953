import { omit } from 'lodash';
import { gql } from '@apollo/client';
import { COMMON_LIST_ARGS, LANGUAGE_FIELDS, toGqlArgs, toGqlVariables } from 'api/utils';

const LIST_ARGS = {
  ...omit(COMMON_LIST_ARGS, ['status']),
  userId: 'ID',
  testId: 'ID',
};

const LIST = gql`
  query ListScholarshipEntries${toGqlVariables(LIST_ARGS)} {
    items: scholarshipEntries${toGqlArgs(LIST_ARGS)} {
      nodes {
        id
        userId
        testId
        user{
          id
          name
          email
          mobile
          status
          city
          emailVerified
          mobileVerified
          signupSource
          createdAt
          signupReferer
          isPlusMember
        }
        test {
          id
          name { ${LANGUAGE_FIELDS} }
        }
        createdAt
      }
      totalCount
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { LIST };

import { gql } from '@apollo/client';
import {
  COMMON_LIST_ARGS,
  COMMON_SEARCH_ARGS,
  toGqlVariables,
  toGqlArgs,
  IMAGE_FIELDS,
} from '../utils';

const USER_ARGS = {
  mobile: 'String',
  email: 'String',
  role: 'UserRole',
  emailVerified: 'Boolean',
  mobileVerified: 'Boolean',
  signupSource: 'SignupSourceType',
  referralCode: 'String',
};
const LIST_ARGS = { ...COMMON_LIST_ARGS, ...USER_ARGS };
const SEARCH_ARGS = { ...COMMON_SEARCH_ARGS, ...USER_ARGS };

const commonFields = `
  id
  mobile
  email
  status
  signupReferer
  referralCode
  role: roleName
`;

const listFields = `
  ${commonFields}
  name
  emailVerified
  mobileVerified
  signupSource
  isPlusMember
  createdAt
  updatedAt
`;

const GET = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      ${commonFields}
      firstName
      lastName
      about
      language
      avatar { ${IMAGE_FIELDS} }
      referralCount
      dailyReferralTarget
      referralBonusAmount
    }
  }
`;

const LIST = gql`
  query ListUsers${toGqlVariables(LIST_ARGS)} {
    items: users${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${listFields}
        coins
        currentStreak
        longestStreak
        streakUpdatedAt
        streakStats {
          testsAttemptCount
          questionsAttemptCount
          postsReadCount
          totalStudyTime
        }
        location {
          city
          state
          country
        }
      }
      totalCount
    }
  }
`;

const CREATE = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      ${listFields}
    }
  }
`;

const UPDATE = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      ${listFields}
    }
  }
`;

const SEARCH = gql`
  query SearchUsers${toGqlVariables(SEARCH_ARGS)} {
    items: users${toGqlArgs(SEARCH_ARGS)} {
      nodes {
        value: id
        label: name
      }
    }
  }
`;

export { GET, LIST, CREATE, UPDATE, SEARCH, commonFields };
